import React, { useState } from 'react';
import './Cart.css';

const Cart = ({ cartItems, removeFromCart }) => {
  const [selectedHours, setSelectedHours] = useState({});
  const [addTent, setAddTent] = useState(false);
  const [addBackdrop, setAddBackdrop] = useState(false);

  // Form data
  const [formData, setFormData] = useState({
    eventDate: '',
    eventLocation: '',
    eventType: '',
    email: '',
    phoneNumber: '',
    message: '',
  });

  // Function to calculate total price based on hours and item type
  const calculateTotalPrice = (item, hours) => {
    let basePrice = 0;

    // Handle pricing for Photo Booth
    if (item.name === 'Duże Fotolustro' || item.name === 'Fotolustro w Kształcie Serca') {
      if (hours === 2) {
        basePrice = 690;
      } else if (hours === 3) {
        basePrice = 890;
      } else if (hours === 4) {
        basePrice = 1100;
      } else if (hours >= 5) {
        return "Cena indywidualna";
      }
    }
    
    // Handle pricing for Platforma 360
    else if (item.name === 'Platforma 360') {
      if (hours === 2) {
        basePrice = 800;
      } else if (hours === 3) {
        basePrice = 1100;
      } else if (hours === 4) {
        basePrice = 1300;
      } else if (hours >= 5) {
        return "Cena indywidualna";
      }
    }

    // Add extra charges for tent and backdrop
    const additionalPrice = (addTent ? 200 : 0) + (addBackdrop ? 300 : 0);

    return basePrice + additionalPrice;
  };

  // Calculate total price for cart
  const totalCartPrice = cartItems.reduce((total, item) => {
    const hours = selectedHours[item.name] || 2; // Default to 2 hours
    const price = calculateTotalPrice(item, hours);
    return total + (typeof price === 'number' ? price : 0); // Add price if it's a number
  }, 0);

  // Deposit and final price calculation
  const depositAmount = 100;
  const finalPrice = totalCartPrice - depositAmount < 0 ? 0 : totalCartPrice - depositAmount;

  // Handle form changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="cart-page">
      <h2>Twój koszyk</h2>
      {cartItems.length === 0 ? (
        <p>Koszyk jest pusty.</p>
      ) : (
        <div className="cart-items">
          {cartItems.map((item, index) => {
            const hours = selectedHours[item.name] || 2; // Default to 2 hours
            return (
              <div key={index} className="cart-item">
                <p>{item.name}</p>
                <p>Wybór czasu wynajmu:</p>
                <select
                  value={hours}
                  onChange={(e) => setSelectedHours({ ...selectedHours, [item.name]: Number(e.target.value) })}
                  className="select-input"
                >
                  <option value="2">2 godziny</option>
                  <option value="3">3 godziny</option>
                  <option value="4">4 godziny</option>
                  <option value="5">5 godzin i więcej – wycena indywidualna</option>
                </select>
                <p>Kwota za ten produkt: {calculateTotalPrice(item, hours)} zł</p>
                <button className="remove-button" onClick={() => removeFromCart(index)}>Usuń</button>
              </div>
            );
          })}
        </div>
      )}

      {cartItems.length > 0 && (
        <>
          <div className="cart-total">
            <h3>Łączna kwota: {totalCartPrice} zł</h3>
            <p>Kwota zaliczki: {depositAmount} zł (wymagana do rezerwacji)</p>
            <h3>Kwota do zapłaty po odliczeniu zaliczki: {finalPrice} zł</h3>
          </div>

          <div className="extra-options">
            <h3>Opcje dodatkowe</h3>
            <div>
              <input
                type="checkbox"
                id="addTent"
                checked={addTent}
                onChange={() => setAddTent(!addTent)}
              />
              <label htmlFor="addTent">Dmuchany namiot (+200 zł)</label>
            </div>
            <div>
              <input
                type="checkbox"
                id="addBackdrop"
                checked={addBackdrop}
                onChange={() => setAddBackdrop(!addBackdrop)}
              />
              <label htmlFor="addBackdrop">Indywidualna ścianka (+300 zł)</label>
            </div>
          </div>

          <div className="order-form">
            <h3>Formularz zamówienia</h3>
            <form>
              <div className="form-group">
                <label>Data wydarzenia:</label>
                <input
                  type="date"
                  name="eventDate"
                  value={formData.eventDate}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Miejsce wydarzenia:</label>
                <input
                  type="text"
                  name="eventLocation"
                  value={formData.eventLocation}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Rodzaj wydarzenia:</label>
                <input
                  type="text"
                  name="eventType"
                  value={formData.eventType}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Adres e-mail:</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Numer telefonu:</label>
                <input
                  type="tel"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Wiadomość:</label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                />
              </div>
              <button type="submit" className="submit-button">Rezerwuję</button>
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default Cart;







