import React, { useState } from 'react';
import axios from 'axios';
import './Gallery.css'; // Stwórz plik CSS dla stylów

const Gallery = () => {
  const [password, setPassword] = useState('');
  const [images, setImages] = useState([]);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Resetowanie błędów

    try {
      const response = await axios.post('/api/getImages', { password });
      setImages(response.data.images); // Zakładając, że zwracasz listę zdjęć
    } catch (err) {
      setError('Nieprawidłowe hasło lub błąd serwera.');
      console.error('Error fetching images', err);
    }
  };

  return (
    <div className="gallery-section">
      <h2>Galeria</h2>
      <form onSubmit={handleSubmit} className="gallery-form">
        <input 
          type="password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          placeholder="Wpisz hasło" 
          required 
        />
        <button type="submit">Pobierz zdjęcia</button>
      </form>
      {error && <p className="error-message">{error}</p>}
      <div className="gallery">
        {images.map((image, index) => (
          <div key={index} className="gallery-item">
            <img src={image.url} alt={`Zdjęcie ${index + 1}`} className="gallery-image" />
            <a href={image.url} download className="download-link">Pobierz</a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
