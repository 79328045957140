import React from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet
import './Platform360.css'; // Stylizacja CSS

const Platform360 = ({ addToCart }) => {
  const handleAddToCart = () => {
    addToCart({
      name: 'Platforma 360',
      basePrice: 800, // Dodanie ceny bazowej
      hours: 2,
      addTent: false,
      addBackdrop: false
    });
  };

  return (
    <div className="platform360-section">
      <Helmet>
        <title>Platforma 360 – Nowoczesne Rozwiązania na Twoją Imprezę</title>
        <meta name="description" content="Wynajmij naszą Platformę 360, aby stworzyć dynamiczne nagrania w formacie 360 stopni. Idealne na każdą imprezę!" />
        <meta name="keywords" content="Platforma 360, wynajem, nagrania 360 stopni, impreza, atrakcje" />
      </Helmet>

      <h2 className="platform360-heading">Platforma 360 – Nowoczesne Rozwiązania na Twoją Imprezę</h2>
      <p className="platform360-paragraph">
        Platforma 360 to nowoczesne rozwiązanie, które pozwala tworzyć dynamiczne nagrania w formacie 360 stopni, 
        uchwytując każdy moment z wyjątkową perspektywą. To atrakcja, która doda prestiżu każdemu wydarzeniu, 
        a pamiątki w postaci nagrań będą wspominane przez długi czas.
      </p>

      <div className="platform360-images">
        <img 
        src="https://storage.googleapis.com/fotobudka360/images/360.jpg" 
        alt="Platforma 360" 
        className="platform360-image" 
        />
        <img 
        src="https://storage.googleapis.com/fotobudka360/images/360LED.jpg" 
        alt="Platforma 360 z oświetleniem" 
        className="platform360-image" 
        />
      </div>


      <h3 className="platform360-offer">Oferta wynajmu:</h3>
      <ul className="platform360-list">
        <li>❖ 2 godziny zabawy – 800 zł</li>
        <li>❖ Dodatkowa godzina – 300 zł</li>
        <li>❖ Indywidualna cena przy wynajmie na 5 godzin lub więcej</li>
      </ul>

      <h3 className="platform360-include">W cenie wynajmu otrzymujesz:</h3>
      <ul className="platform360-list">
        <li>❖ Indywidualny podpis lub logo firmy na nagraniu</li>
        <li>❖ Czerwony dywan i słupki odgradzające w stylu VIP</li>
        <li>❖ Oświetlenie LED – neonowe i efektowne</li>
        <li>❖ Gadżety: kapelusze, okulary, pistolety na bańki i pieniądze</li>
        <li>❖ Oprawa muzyczna</li>
        <li>❖ Obsługa właścicieli</li>
      </ul>

      <h3 className="platform360-additional">Opcje dodatkowe:</h3>
      <ul className="platform360-list">
        <li>❖ Namiot z oświetleniem LED – dodatkowe 200 zł</li>
        <li>❖ Indywidualna ścianka z personalizowanym tłem – dodatkowe 300 zł</li>
      </ul>

      <h3 className="platform360-transport">Dojazd:</h3>
      <p className="platform360-paragraph">
        ❖ Dojazd gratis: Wałbrzych, Szczawno-Zdrój i obszar w promieniu 30 km od Wałbrzycha.<br />
        ❖ Powyżej 30 km: 2 zł za każdy dodatkowy kilometr.
      </p>

      <button className="platform360-button" onClick={handleAddToCart}>
        Rezerwuj
      </button>
    </div>
  );
};

export default Platform360;
