import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet
import './Slogan.css';
import { FaMapMarkerAlt, FaCamera, FaRegSmileBeam } from 'react-icons/fa'; // Dodanie ikon

const Slogan = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    'https://storage.googleapis.com/fotobudka360/images/GLL2.jpg',
    'https://storage.googleapis.com/fotobudka360/images/front5.jpg',
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Zmiana co 5 sekund
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="slogan-section">
      <Helmet>
        <title>Fotobudki i Platformy 360</title>
        <meta name="description" content="Wynajmujemy fotobudki i Platformy 360 na wszystkie okazje! Uczcij swoje wydarzenia z nami." />
        <meta name="keywords" content="fotobudki, platformy 360, wynajem, imprezy, wesele, urodziny" />
      </Helmet>

      <div
        className="slogan-background"
        style={{
          backgroundImage: `url(${images[currentImageIndex]})`,
        }}
      >
        <div className="overlay" /> {/* Półprzezroczyste tło */}
        <div className="text-container">
          <h1 className="slogan-header">Fotobudki i Platformy 360</h1>
          <p className="sub-header">Wynajmujemy fotobudki i Platformy 360 na wszystkie okazje!</p>
          <div className="event-list">
            <div className="neon-item neon-item1">
              <FaCamera className="icon" /> Wesele
            </div>
            <div className="neon-item neon-item2">
              <FaRegSmileBeam className="icon" /> Studniówki
            </div>
            <div className="neon-item neon-item3">
              <FaCamera className="icon" /> Urodziny
            </div>
            <div className="neon-item neon-item4">
              <FaRegSmileBeam className="icon" /> Imprezy firmowe
            </div>
            <div className="neon-item neon-item5">
              <FaCamera className="icon" /> Eventy plenerowe
            </div>
            <div className="neon-item neon-item6">
              <FaRegSmileBeam className="icon" /> Osiemnastki
            </div>
            <div className="neon-item neon-item7">
              <FaCamera className="icon" /> Festiwale
            </div>
            <div className="neon-item neon-item8">
              <FaRegSmileBeam className="icon" /> Bale karnawałowe
            </div>
          </div>
          {currentImageIndex === 0 ? (
            <p className="sub-header">Chwile pełne magii, uchwyć wspomnienia z nami!</p>
          ) : (
            <div className="cities">
              <FaMapMarkerAlt /> Wałbrzych
              <FaMapMarkerAlt /> Świdnica
              <FaMapMarkerAlt /> Dzierżoniów
              <FaMapMarkerAlt /> Jawor
              <FaMapMarkerAlt /> Kłodzko
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Slogan;
