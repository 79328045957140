import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Importowanie Helmet
import Navbar from './components/Navbar';
import Slogan from './components/Slogan';
import Products from './components/Products';
import AboutUs from './components/AboutUs';
import Footer from './components/Footer';
import Platform360 from './components/Platform360';
import Cart from './components/Cart'; 
import PhotoMirrorPage from './components/PhotoMirrorPage'; 
import Gallery from './components/Gallery'; 
import Contact from './components/Contact'; 
import TentSection from './components/TentSection';
import PrivacyPolicy from './components/PrivacyPolicy';

function App() {
  const [cartItems, setCartItems] = useState([]);

  const addToCart = (item) => {
    setCartItems([...cartItems, item]);
  };

  const removeFromCart = (index) => {
    const newCartItems = cartItems.filter((_, i) => i !== index);
    setCartItems(newCartItems);
  };

  return (
    <Router>
      <div className="App">
        <Helmet>
          <title>Wynajem Fotoluster - Fotolustro na Wesele i Inne Wydarzenia</title>
          <meta name="description" content="Wynajem nowoczesnych fotoluster na wesela i inne wydarzenia. Interaktywne zdjęcia i niezapomniane wspomnienia!" />
          <meta name="keywords" content="wynajem fotoluster, fotolustro, fotobudka, wydarzenia, wesele" />
        </Helmet>

        <Navbar cartItems={cartItems} />
        
        <Routes>
          <Route path="/" element={<><Slogan /><Products /><AboutUs /><TentSection /></>} />
          <Route path="/platforma360" element={<Platform360 addToCart={addToCart} />} />
          <Route path="/cart" element={<Cart cartItems={cartItems} removeFromCart={removeFromCart} />} />
          <Route path="/fotolustra" element={<PhotoMirrorPage addToCart={addToCart} />} />
          <Route path="/galeria" element={<Gallery />} />
          <Route path="/kontakt" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
