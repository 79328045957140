import React from 'react';
import './Products.css';

const Products = () => {
  return (
    <div className="products-section">
  <h2 className="products-heading">Nasze Produkty</h2>
  <div className="products-container">
    <a href="/fotolustra" className="product-item">
      <div className="product-frame left">
        <img 
          src="https://storage.googleapis.com/fotobudka360/fotolustrogl.jpg" 
          alt="Fotolustra" 
          className="product-image" 
        />
        <div className="product-title left">Fotolustra</div>
      </div>
    </a>
    <a href="/platforma360" className="product-item">
      <div className="product-frame right">
        <img 
          src="https://storage.googleapis.com/fotobudka360/360to.svg" 
          alt="Platforma 360" 
          className="product-image" 
        />
        <div className="product-title right">Platforma 360</div>
      </div>
    </a>
  </div>
</div>

  );
};

export default Products;






