import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import './Footer.css';

const Footer = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const logo = "https://storage.googleapis.com/fotobudka360/images/logostop.jpg";

  const cities = [
    'Wałbrzych',
    'Zagórze Śląskie',
    'Świdnica',
    'Kamienna Góra',
    'Ząbkowice Śląskie',
    'Dzierżoniów',
    'Bielawa',
    'Strzegom',
    'Boguszów-Gorce',
    'Jedlina-Zdrój',
    'Krzeszów',
  ];

  const handleScroll = () => {
    const isAtBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight;
    setIsExpanded(isAtBottom);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <footer className={`footer ${isExpanded ? 'expanded' : ''}`}>
      <div className="footer-content">
        <div className="footer-left">
          <img src={logo} alt="Logo" className="footer-logo" />
          <div className="company-name">Uśmiech 360</div>
        </div>
        <div className="footer-middle">
          <h4>Kontakt :</h4>
          <div className="contact-info">
            <p>
              Tel: <a href="tel:+48535479804">535 479 804</a>
              <br />
              Tel: <a href="tel:+48792843052">792 843 052</a>
            </p>
            <p>
              Email: <a href="mailto:usmiech360@wp.pl">usmiech360@wp.pl</a>
            </p>
          </div>
          <div className="policy">
            <Link to="/privacy-policy" className="footer-link">Polityka prywatności</Link>
          </div>
        </div>
        <div className="footer-right">
          <div className="cities">
            <h4>Dojedziemy bezpłatnie do każdego miasta w promieniu 30 km od Wałbrzycha, w tym m.in.:</h4>
            <ul>
              {cities.map((city, index) => (
                <li key={index}>{city}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© {new Date().getFullYear()} Uśmiech 360. Wszelkie prawa zastrzeżone.</p>
      </div>
    </footer>
  );
};

export default Footer;









