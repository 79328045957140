import React from 'react';


const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Polityka Prywatności</h1>
      <p><strong>Data ostatniej aktualizacji: [Data]</strong></p>

      <p>
        Twoja prywatność jest dla nas bardzo ważna. Niniejsza polityka prywatności określa, 
        jakie dane osobowe zbieramy od użytkowników naszej strony internetowej, jak je wykorzystujemy 
        oraz jakie środki bezpieczeństwa stosujemy w celu ochrony tych danych.
      </p>

      <h2>1. Jakie dane zbieramy?</h2>
      <p>
        Zbieramy dane osobowe, które użytkownicy dobrowolnie podają podczas korzystania z naszej 
        strony, w tym:
      </p>
      <ul>
        <li>Imię i nazwisko</li>
        <li>Adres e-mail</li>
        <li>Numer telefonu</li>
        <li>Data wydarzenia</li>
        <li>Miejsce wydarzenia</li>
        <li>Rodzaj wydarzenia</li>
        <li>Wiadomości</li>
      </ul>

      <h2>2. Jak wykorzystujemy zebrane dane?</h2>
      <p>
        Zebrane dane mogą być wykorzystywane w następujący sposób:
      </p>
      <ul>
        <li>Do przetwarzania rezerwacji i zamówień</li>
        <li>Do komunikacji z użytkownikami w sprawach dotyczących ich zamówień</li>
        <li>Do poprawy naszej strony internetowej oraz świadczonych usług</li>
        <li>Do przesyłania informacji o promocjach i nowościach (jeśli użytkownik wyraził na to zgodę)</li>
      </ul>

      <h2>3. Jak chronimy dane osobowe?</h2>
      <p>
        Stosujemy odpowiednie środki bezpieczeństwa, aby chronić dane osobowe przed nieautoryzowanym 
        dostępem, zmianą, ujawnieniem lub zniszczeniem. Nasza strona korzysta z protokołu SSL, 
        aby zapewnić bezpieczną transmisję danych.
      </p>

      <h2>4. Udostępnianie danych osobowych</h2>
      <p>
        Nie sprzedajemy ani nie udostępniamy danych osobowych osobom trzecim bez zgody użytkownika, 
        chyba że jest to wymagane przez prawo lub w celu wykonania zamówienia.
      </p>

      <h2>5. Twoje prawa</h2>
      <p>
        Użytkownicy mają prawo do:
      </p>
      <ul>
        <li>Dostępu do swoich danych osobowych</li>
        <li>Korekty swoich danych</li>
        <li>Żądania usunięcia swoich danych</li>
        <li>Ograniczenia przetwarzania swoich danych</li>
      </ul>

      <h2>6. Zmiany w polityce prywatności</h2>
      <p>
        Zastrzegamy sobie prawo do wprowadzania zmian w niniejszej polityce prywatności. O wszelkich 
        zmianach poinformujemy użytkowników za pośrednictwem naszej strony internetowej.
      </p>

      <h2>7. Kontakt</h2>
      <p>
        W przypadku pytań dotyczących polityki prywatności lub przetwarzania danych osobowych 
        prosimy o kontakt na adres e-mail: usmiech360@wp.pl.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
