import React from 'react';
import './TentSection.css';

const TentSection = () => {
  return (
    <div className="tent-container">
      <div className="tent-grid">
        <div className="tent-image-container">
        <img 
          src="https://storage.googleapis.com/fotobudka360/images/namiot2.jpg" 
          alt="Dmuchany namiot" 
          className="tent-image" 
        />

        </div>
        <div className="tent-info">
          <h3 className="tent-title">Wynajmij dmuchany namiot z LED-ami!</h3>
          <p className="tent-price">Za jedyne 200 zł możesz wynająć nasz niesamowity namiot!</p>
          <p className="tent-description">Dmuchany namiot z oświetleniem LED to idealne rozwiązanie na każde wydarzenie. Oto dlaczego warto go wybrać:</p>
          <ul className="tent-benefits-list">
            <li><strong>Tworzy wyjątkową atmosferę:</strong> LED-y w różnych kolorach dodają magii każdemu wydarzeniu.</li>
            <li><strong>Łatwy w montażu:</strong> Namiot jest prosty w rozkładaniu i demontażu, co oszczędza czas przed imprezą.</li>
            <li><strong>Wielofunkcyjność:</strong> Idealny zarówno na imprezy plenerowe, jak i wewnętrzne.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TentSection;
