import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = ({ cartItems }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
      <div className="navbar-logo">
        <img src="https://storage.googleapis.com/fotobudka360/logo.svg" alt="Logo" className="logo" />
        <span className="navbar-title">Uśmiech 360</span>
     </div>

        <div className="navbar-toggle" onClick={toggleMenu}>
          {menuOpen ? '✖' : '☰'} {/* Przycisk X do zamykania menu */}
        </div>
      </div>
      <ul className={`navbar-menu ${menuOpen ? 'active' : ''}`}>
        <li><Link to="/">Strona Główna</Link></li>
        <li><Link to="/platforma360">Platforma 360</Link></li>
        <li><Link to="/fotolustra">Fotobudka</Link></li>
        <li><Link to="/galeria">Galeria</Link></li>
        <li><Link to="/kontakt">Kontakt</Link></li>
        <li>
          <Link to="/cart" className="cart-link">
            <span role="img" aria-label="Koszyk" className="cart-icon">🛒</span> Koszyk ({cartItems.length})
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
