import React, { useState } from 'react';
import { Helmet } from 'react-helmet'; // Importowanie Helmet
import './PhotoMirrorPage.css';

const PhotoMirrorPage = ({ addToCart }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const openImage = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="photo-mirror-page">
      <Helmet>
        <title>Wynajem Fotoluster - Fotolustro na Wesele i Inne Wydarzenia</title>
        <meta name="description" content="Wynajem nowoczesnych fotoluster na wesela i inne wydarzenia. Interaktywne zdjęcia i niezapomniane wspomnienia!" />
        <meta name="keywords" content="wynajem fotoluster, fotolustro, fotobudka, wydarzenia, wesele" />
      </Helmet>
      
      <header className="page-header">
        <h1 className="main-heading">Fotolustro na Wesele i Inne Wydarzenia</h1>
        <p className="intro">Nowoczesna Zabawa w Świecie Fotografii!</p>
      </header>

      <div className="content">
        <div className="image-container">
          <div className="image-grid">
            {["11.jpg", "12.jpg", "13.jpg", "wydruk.jpg"].map((image, index) => (
              <img 
                key={index} 
                src={`https://storage.googleapis.com/fotobudka360/images/${image}`} 
                alt={`Zdjęcie ${index + 1}`} 
                className="grid-image" 
                onClick={() => openImage(`https://storage.googleapis.com/fotobudka360/images/${image}`)} 
              />
            ))}
          </div>
        </div>
        <div className="description">
          <h2>Fotolustro w Kształcie Serca - Wypożycz</h2>
          <p>
            <strong>Co to jest fotolustro w kształcie serca?</strong> 
            To niezwykłe, interaktywne urządzenie, które nie tylko uwieczni najpiękniejsze chwile Twojego wydarzenia, ale również dostarczy gościom niezapomnianych wrażeń.
          </p>
          <h3>Dlaczego warto wynająć fotolustro?</h3>
          <ul>
            <li>Interaktywna zabawa: Twoi goście będą mogli stworzyć kreatywne zdjęcia z różnymi efektami, które z pewnością ich zachwycą.</li>
            <li>Personalizacja: Dodaj własne napisy, grafiki i filtry, aby zdjęcia były jeszcze bardziej wyjątkowe!</li>
            <li>Pamiątka z imprezy: Goście będą mogli zabrać ze sobą wydrukowane zdjęcia jako wyjątkową pamiątkę z tego magicznego dnia.</li>
          </ul>
          <h3>Nie czekaj! Zarezerwuj już dziś!</h3>
          <button onClick={() => addToCart({ name: 'Fotolustro w Kształcie Serca', price: 690 })} className="reservation-button">Zarezerwuj Teraz!</button>
          <ul className="info-list">
            <li>2 godziny – 690 zł</li>
            <li>3 godziny – 890 zł</li>
            <li>4 godziny – 1100 zł</li>
            <li>5 godzin i więcej – wycena indywidualna (dopasowana do Twoich potrzeb)</li>
          </ul>
        </div>
      </div>

      <div className="content">
        <div className="image-container">
          <div className="image-grid">
            {["20.jpg", "21.jpg", "22.jpg", "23.jpg"].map((image, index) => (
              <img 
                key={index} 
                src={`https://storage.googleapis.com/fotobudka360/images/${image}`} 
                alt={`Zdjęcie ${index + 1}`} 
                className="grid-image" 
                onClick={() => openImage(`https://storage.googleapis.com/fotobudka360/images/${image}`)} 
              />
            ))}
          </div>
        </div>
        <div className="description">
          <h2>Duże Fotolustro - Wypożycz</h2>
          <p>
            <strong>Co to jest duże fotolustro?</strong> 
            To interaktywne lustro z funkcją robienia zdjęć w pełnym wymiarze, idealne na wszelkie wydarzenia. Dzięki niemu Twoi goście będą mieli wyjątkową okazję stworzyć personalizowane fotografie w eleganckim stylu.
          </p>
          <h3>Dlaczego warto wynająć duże fotolustro?</h3>
          <ul>
            <li>Profesjonalna jakość zdjęć: Nowoczesne technologie zapewniają doskonałą jakość każdej fotografii.</li>
            <li>Opcje interaktywne: Duże lustro oferuje mnóstwo kreatywnych możliwości – od grafik po personalizowane podpisy.</li>
            <li>Pamiątka na zawsze: Zdjęcia wydrukowane na miejscu to wyjątkowy upominek dla gości.</li>
          </ul>
          <h3>Spraw, aby Twoje wydarzenie było niezapomniane!</h3>
          <button onClick={() => addToCart({ name: 'Duże Fotolustro', price: 690 })} className="reservation-button">Zarezerwuj Teraz!</button>
          <ul className="info-list">
            <li>2 godziny – 690 zł</li>
            <li>3 godziny – 890 zł</li>
            <li>4 godziny – 1100 zł</li>
            <li>5 godzin i więcej – wycena indywidualna (dopasowana do Twoich potrzeb)</li>
          </ul>
        </div>
      </div>

      <div className="additional-info-section">
        <h2 className='co'>Co otrzymujesz, wynajmując jedno z naszych fotoluster?</h2>
        <p>
          Wynajmując nasze fotolustro, zyskujesz nie tylko wyjątkową atrakcję dla Twojego wydarzenia, ale także pełen pakiet usług, który zapewni Twoim gościom doskonałą zabawę i niezapomniane wspomnienia.
        </p>
        <h3>W pakiecie otrzymujesz:</h3>
        <ul className="info-list">
          <li>Profesjonalne fotolustro – nowoczesne urządzenie z eleganckim, interaktywnym designem.</li>
          <li>Nielimitowana liczba zdjęć – Twoi goście mogą robić dowolną ilość zdjęć.</li>
          <li>Personalizowane wydruki z indywidualnym podpisem, logo lub grafiką.</li>
          <li>Różnorodne tła, filtry i efekty specjalne, które sprawiają, że każde zdjęcie staje się wyjątkowe.</li>
          <li>Wbudowane animacje i zabawne gify, które prowadzą użytkowników przez proces robienia zdjęcia.</li>
          <li>Dostęp do galerii online, skąd goście mogą pobrać swoje fotografie.</li>
          <li>Integracja z mediami społecznościowymi – możliwość udostępniania zdjęć bezpośrednio na Facebooku, Instagramie i Twitterze.</li>
          <li>Szybki wydruk zdjęć na miejscu w wysokiej jakości oraz możliwość pokazów zdjęć na żywo.</li>
          <li>Interaktywna zabawa z gośćmi – fotolustro zachęca do kreatywnych zdjęć z różnorodnymi animacjami i efektami.</li>
          <li>Możliwość umieszczenia dowolnego ekranu startowego, np. logo firmy.</li>
          <li>Obsługa techniczna – nasz personel zapewni wsparcie podczas całego wydarzenia.</li>
          <li>Dodatkowe gadżety – okulary, kapelusze, maski i inne akcesoria, które urozmaicą zdjęcia.</li>
          <li>Pokaz zdjęć z aktualnie obsługiwanej imprezy, wzbudzający radość i zainteresowanie gości.</li>
          <li>Pokaz reklam oraz zdjęć dostarczonych wcześniej przez organizatora, np. zdjęcia pary młodej z młodości.</li>
        </ul>  
      </div>

      {selectedImage && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content">
            <img src={selectedImage} alt="Podgląd zdjęcia" className="modal-image" />
          </div>
        </div>
      )}
    </div>
  );
};

export default PhotoMirrorPage;
