import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="photo-booth-container">
      <div className="photo-booth-grid">
        <div className="photo-booth-text">
          <h2 className="photo-booth-title">Dlaczego warto mieć fotolustro na weselu?</h2>
          <p>Fotolustro to niezastąpiona atrakcja, która wzbogaca każde wydarzenie, w tym wesela, jubileusze czy imprezy firmowe. Oto kilka powodów, dla których warto zainwestować w fotolustro:</p>
          <ul className="photo-booth-benefits-list">
            <li><strong>Niepowtarzalne wspomnienia:</strong> Goście mogą robić zdjęcia i od razu zabrać ze sobą wydruki, co tworzy wyjątkowe pamiątki z wydarzenia.</li>
            <li><strong>Interaktywność:</strong> Fotolustro angażuje uczestników, co sprawia, że atmosfera staje się radosna i zabawna.</li>
            <li><strong>Personalizacja:</strong> Możliwość dodania własnych ramek, tła i napisów do zdjęć, co czyni je jeszcze bardziej unikalnymi.</li>
            <li><strong>Wysoka jakość zdjęć:</strong> Profesjonalny sprzęt zapewnia doskonałą jakość zdjęć, które są prawdziwymi dziełami sztuki.</li>
            <li><strong>Rodzinna firma:</strong> Jesteśmy rodzinną firmą, która osobiście obsługuje każde wydarzenie. Dbamy o każdy detal, aby zapewnić naszym klientom najwyższą jakość usług.</li>
          </ul>
        </div>
        <div className="photo-booth-image-container">
        <img src="https://storage.googleapis.com/fotobudka360/images/T1.jpg" alt="Fotolustro" className="photo-booth-image" />

        </div>
      </div>
    </div>
  );
};

export default AboutUs;



