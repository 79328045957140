import React, { useRef } from "react";
import emailjs from "emailjs-com";
import './Contact.css'; // Import stylów

const ContactForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_nqv7qyb",  // Twój Service ID
      "template_pbot0wd",  // Twój Template ID
      form.current,
      "XrUMTyUO-7FS-WsYC"  // Twój User ID (klucz publiczny)
    )
    .then(
      (result) => {
        console.log("Wiadomość wysłana: ", result.text);
        alert("Wiadomość została wysłana pomyślnie!");
      },
      (error) => {
        console.log("Błąd: ", error.text);
        alert("Wystąpił problem z wysłaniem wiadomości.");
      }
    );
  };

  return (
    <div className="contact-container">
      <h2>Skontaktuj się z nami</h2>
      <form ref={form} onSubmit={sendEmail}>
        <label>Imię</label>
        <input type="text" name="from_name" required />

        <label>Email</label>
        <input type="email" name="user_email" required />

        <label>Wiadomość</label>
        <textarea name="message" required />

        <button type="submit">Wyślij</button>
      </form>

      <div className="contact-info">
        <p>Telefon 1: 535 479 804</p>
        <p>Telefon 2: 792 843 052</p>
        <p>Email: <a href="mailto:usmiech360@wp.pl">usmiech360@wp.pl</a></p>
      </div>

      <div className="questions">
        <p>Masz pytania? Chcesz się czegoś dowiedzieć?</p>
        <p>Skontaktuj się z nami telefonicznie lub napisz do nas!</p>
      </div>
    </div>
  );
};

export default ContactForm;
